import React, { useEffect } from 'react';
import { FaCogs, FaBolt, FaBalanceScale, FaChartPie, FaShieldAlt, FaRocket, FaDatabase } from 'react-icons/fa';
import './Workflow.css';
import { useMediaQuery } from 'react-responsive';
import { FaMicrochip, FaLock, FaLeaf } from 'react-icons/fa';

const Benefits = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        }
      });
    });

    const textBlocks = document.querySelectorAll('.benefit-text-block, .benefit-icon, .line');
    textBlocks.forEach((textBlock) => observer.observe(textBlock));

    return () => {
      textBlocks.forEach((textBlock) => observer.unobserve(textBlock));
    };
  }, []);

  return (
    <div className="business-benefits-line">
      <h2 className="feature-header">Workflow</h2>

      <div className="benefit-row">
        <div className="icon-line">
          <FaCogs className="benefit-icon" />
          {!isMobile && (<div className="line"></div>)}
        </div>
        <div className="benefit-text-block">
          <h3>Step 1: Choose your desired LLM size</h3>
        </div>
      </div>

      <div className="benefit-row">
        <div className="icon-line">
          <FaDatabase className="benefit-icon" />
          {!isMobile && (<div className="line"></div>)}
        </div>
        <div className="benefit-text-block">
          <h3>Step 2: Provide your business data</h3>
        </div>
      </div>

      <div className="benefit-row">
        <div className="icon-line">
          <FaRocket className="benefit-icon" />
          {!isMobile && (<div className="line"></div>)}
        </div>
        <div className="benefit-text-block">
          <h3>Step 3: Hit Submit! Your model will be ready in few hours.</h3>
        </div>
      </div>

    </div>

  );
};

export default Benefits;