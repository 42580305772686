import React from 'react';
import './GithubBanner.css';

const GithubBanner = () => {
  return (
    <div className="github-banner">
      <a
        href="https://github.com/horus-ai-labs/DistillFlow"
        target="_blank"
        rel="noopener noreferrer"
        className="github-link"
      >
        Star us on GitHub
      </a>
    </div>
  );
};

export default GithubBanner;
