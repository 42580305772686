import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import './Blogs.css';
import neural from './neural_net.jpg';
import { blogs, getUrlFriendlyTitle } from './BlogConfig';

//const blogs = [
//  {
//    id: 1,
//    title: "A primer to distillation",
//    excerpt: "Learn about small models and distillation",
//    image: neural,
//    markdownFile: '/blogposts/primer-to-distillation.md',
//  }
//];

const BlogCard = ({ blog }) => {
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(blog.markdownFile);
        const text = await response.text();
        setMarkdownContent(text);
      } catch (error) {
        console.error('Error loading markdown:', error);
        setMarkdownContent('Failed to load blog content');
      }
    };

    fetchMarkdown();
  }, [blog.markdownFile]);

  return (
    <div className="blog-card">
      <img src={blog.image} alt={blog.title} className="blog-image" />
      <div className="blog-content">
        <h3>{blog.title}</h3>
        <p>{blog.excerpt}</p>
        <Link
          to={`/blogs/${encodeURIComponent(
            blog.title.toLowerCase().replace(/\s+/g, '-')
          )}`}
          className="blog-link"
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

const Blogs = () => {
  return (
    <section className="blog-section">
      <h1 className="blog-title">Our Blogs</h1>
      <div className="blog-cards-container">
        {blogs.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
    </section>
  );
};

export default Blogs;