import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import './DistillFlow.css'; // Custom styles
import ProductDemo from './ProductDemo.js';
import ProductFeatures from './ProductFeatures.js';
import Workflow from './Workflow.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButtons from './ActionButtons.js';
import GetInTouch from '../GetInTouch.js';
import GithubBanner from './GithubBanner.js';

const DistillFlow = () => {
  return (
      <div>
        <GithubBanner/>
        <section className="hero">
          <motion.div className="hero-content" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <h1>10X Faster and Cheaper LLMs</h1>
            <p>Models tuned for your business</p>
            <ActionButtons showGithub={false} />
          </motion.div>
        </section>

        <section className="motivation">
        <motion.div className="product-content" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
          <Workflow />
          </motion.div>
        </section>
         <section className="product-section">
          <motion.div className="product-content" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 2 }}>
          <ProductFeatures />
          </motion.div>
        </section>
        <GetInTouch />
     </div>
  );
}

export default DistillFlow;