import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './HomePage';
import Pricing from './Pricing';    // Pricing page component
import Footer from './Footer.js';
import Navbar from './Nav.js';
import DistillFlow from './distill/DistillFlow.js';
import Blogs from "./blogs/Blogs.js";
import './App.css';
import BlogPost from "./blogs/BlogPost.js";
const App = () => {
  return (
    <ParallaxProvider>
      <div className="App">
        <header>
          <Navbar />
        </header>
        <Router>
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Navigate to="/distillflow" replace />} />
              <Route path="/distillflow" element={<DistillFlow />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs/:title" element={<BlogPost />} />
            </Routes>
          </div>
        </Router>
        <Footer />
     </div>
   </ParallaxProvider>
  );
}

export default App;
