import neural from './neural_net.jpg'  // Adjust path as needed

export const blogs = [
  {
    id: 1,
    title: "A primer to distillation",
    excerpt: "Learn about small models and distillation",
    image: neural,
    markdownFile: '/blogposts/primer-to-distillation.md',
  }
  // Add more blog entries here
];

export const getUrlFriendlyTitle = (title) => {
  return title.toLowerCase().replace(/\s+/g, '-');
};

export const findBlogByUrlTitle = (urlTitle) => {
  return blogs.find(blog => getUrlFriendlyTitle(blog.title) === urlTitle);
};