import React, { useEffect, useState, useRef } from 'react';
import { FaCogs, FaBolt, FaBalanceScale, FaChartPie, FaShieldAlt, FaRocket, FaDatabase } from 'react-icons/fa';

import './ProductFeatures.css';
import { FaMicrochip, FaLock, FaLeaf } from 'react-icons/fa';


const ProductFeatures = () => {
  useEffect(() => {
      const revealElements = document.querySelectorAll('.reveal');

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
              observer.unobserve(entry.target); // Stops observing once the animation is triggered
            }
          });
      }, { threshold: 0.2 }); // Triggers when 20% of the card is visible

      revealElements.forEach(element => {
        observer.observe(element);
      });

      return () => {
        revealElements.forEach(element => {
          observer.unobserve(element);
        });
      };
    }, []);

  return (
    <div className="product-features">
      <h2 className="feature-header">How we help your business</h2>
      <div className="feature-cards-container">

        <div className="feature-card reveal">
          <FaBalanceScale className="feature-icon" />
          <h3>Cost Efficiency</h3>
          <p>Replace ChatGPT and slash your costs by up to 90% without compromising enterprise-grade AI performance.</p>
        </div>

        <div className="feature-card reveal">
          <FaBolt className="feature-icon" />
          <h3>Lightning-Fast Response Times</h3>
          <p>Achieve up to 10x faster inference speeds with our models.</p>
        </div>

         <div className="feature-card reveal">
          <FaDatabase className="feature-icon" />
          <h3>Customized models</h3>
          <p>Tailor LLMs to your data for the most relevant AI insights.</p>
        </div>

        <div className="feature-card reveal">
          <FaMicrochip className="feature-icon" />
          <h3>AI on edge devices</h3>
          <p>Deploy powerful AI directly on edge devices, smartphones, and IoT hardware.</p>
        </div>

        <div className="feature-card reveal">
          <FaLock className="feature-icon" />
          <h3>Privacy is a priority</h3>
          <p>Secure your application by owning your model, not just the data. No more sending your data to ChatGPT.</p>
        </div>

        <div className="feature-card reveal">
          <FaShieldAlt className="feature-icon" />
          <h3>Managed or On-Prem Deployment</h3>
          <p>Deploy on-premises for privacy or opt for managed services.</p>
        </div>

         <div className="feature-card reveal">
          <FaLeaf className="feature-icon" />
          <h3>A step towards sustainability</h3>
          <p>Reduce carbon footprint with smaller, efficient models that demand less compute power.</p>
        </div>

      </div>
    </div>
  );
};

export default ProductFeatures;
